<template>
    <div>
        <div class="headerss">
            <span ></span>
            <span>拆车件详情</span>
            <span></span>
        </div>
        <div class="detailMain">
            <div>
                <span>车牌号：</span>
                <input type="text" name="" id="" v-model="labelList.carNumberPlate" readonly="readonly">        
            </div>
            <div>
                <span>车架号：</span>
                <input type="text" name="" id="" v-model="labelList.vin" readonly="readonly">        
            </div>
            <div>
                <span>进厂编号：</span>
                <input type="text" name="" id="" v-model="labelList.jinchangbianhao" readonly="readonly">        
            </div>
            <div>
                <span>拆车件编号：</span>
                <input type="text" name="" id="" v-model="labelList.chaibianhao" readonly="readonly">        
            </div>
            <div>
                <span>拆车件分类：</span>
                <input type="text" name="" id="" v-model="labelList.CarAccessoriesText" readonly="readonly">        
            </div>
            <div>
                <span>拆车件名称：</span>
                <input type="text" name="" id=""  v-model="labelList.patrsName" readonly="readonly">        
            </div>
            <div>
                <span>来源车辆品牌：</span>
                <input type="text" name="" id=""  v-model="labelList.vehicleBrand" readonly="readonly">        
            </div>
            <div>
                <span>来源车辆型号：</span>
                <input type="text" name="" id=""  v-model="labelList.vehicleModel" readonly="readonly">        
            </div>
            <div>
                <span>拆解完成日期：</span>
                <input type="text" name="" id=""  v-model="labelList.dismantleEndTime" readonly="readonly">        
            </div>
            <div>
                <span>vin解析型号：</span>
                <input type="text" name="" id=""  v-model="labelList.vinVehicleModel" readonly="readonly">        
            </div>
            <div>
                <span>vin解析品牌：</span>
                <input type="text" name="" id=""  v-model="labelList.vinVehicleBrand" readonly="readonly">        
            </div>
            <div>
                <span>vin解析年款：</span>
                <input type="text" name="" id=""  v-model="labelList.vinYear" readonly="readonly">        
            </div>
            <!-- <div>
                <span>拆解人：</span>
                <input type="text" name="" id=""  v-model="labelList.carNumberPlate">        
            </div>
            <div>
                <span>库位信息：</span>
                <input type="text" name="" id=""  v-model="labelList.carNumberPlate">        
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    
    data(){
        return{
            labelList:'',
        }
    },
    created(){
        //console.log(this.$route.query.id)
        this.$http.post("/index.php/index/P_Parts_Sales_Library/get",{id:this.$route.query.id}).then(res=>{
            //console.log(res.data.data)
            this.labelList = res.data.data 
            //console.log(this.labelList)
        })
    }
}
</script>
<style lang="scss" scoped> 
    .headerss {
  padding: 0 20px;
  background-color: cadetblue;
  height: 1rem;
  line-height: 1rem;
  color: white;
  font-size: 0.4rem;
  display: flex;

  justify-content: space-between;
  span {
    display: inline-block;
  }
  span:nth-child(1) {
    width: 30px;
    height: 30px;
    background-size: 100%;
    margin-top: 8px;
  }
}
  .detailMain {
        width: 90%;
        margin: 10px auto;
         font-size: 0.35rem;
          div {
            margin-top: 10px;
            span {
                width: 3.5rem;
                display: inline-block;
                text-align: center;
                margin-right: 23px;
            }
            input {
                width: 4rem;
                height: 0.5rem;
            }
        }
    }
</style>